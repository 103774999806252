import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Colors } from 'common-constants/colors'
import { AgreementLink } from 'components/page/Legal/Agreement/AgreementLink'

import { SupportButton } from '../SupportButton/SupportButton'

export const StyledSocialNote = styled.div`
  font-size: 12px;
  margin: 10px 0 20px 0;
  color: ${Colors.noteColor};
`

const SupportButtonWrapper = styled.div`
  margin-top: 5px;
`

interface SocialNoteProps {
  showSupport?: boolean
  className?: string
}

export const SocialNote: FC<SocialNoteProps> = ({
  showSupport = false,
  className,
}) => {
  return (
    <StyledSocialNote className={className}>
      <AgreementLink />
      {showSupport && (
        <SupportButtonWrapper>
          <SupportButton />
        </SupportButtonWrapper>
      )}
    </StyledSocialNote>
  )
}
