import {
  REGISTER_ACTION,
  RegistrationAction,
} from 'actions/authorization/registrationAction'
import {
  REGISTRATION_DATA,
  RegistrationDataAction,
} from 'actions/authorizationAction'
import { REGISTER_WITH_EMAIL } from 'actions/form/stepRegisterWithEmailAction'
import {
  CLEAR_STEP_REGISTRATION,
  FETCH_REGISTRATION_SEARCH_RESULT,
  FETCH_REGISTRATION_SEARCH_SETTINGS,
  RegistrationActionTypes,
  SAVE_STEP_REGISTRATION_DATA,
  SELECT_REGISTRATION_AGE,
  SELECT_REGISTRATION_TARGET_GENDER,
  TOGGLE_CONSTITUTION,
  SET_DATING_GOAL,
  SET_PERSON_HEIGHT,
  SET_PERSON_WEIGHT,
  UPDATE_REGISTRATION_FIELD,
  UPDATE_REGISTRATION_GENDER,
} from 'actions/form/stepRegistrationAction'
import {
  LocalStorageActionTypes,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { extractFormErrors } from 'api/FormBuilder'
import { extractFieldsValues } from 'api/index'
import { FieldVariant } from 'api/search/fetchSearchSettingsListApi'
import { Constitution, DatingGoals, Gender } from 'common-constants/api6.types'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { defaultUpdateFieldReducer } from 'reducers/defaultUpdateFieldReducer'
import { toggleArrayValue } from 'reducers/reducers.functions'

export enum StepRegistrationFieldList {
  name = 'name',
  day = 'day',
  month = 'month',
  year = 'year',
  email = 'email',
}

export interface NumberRange {
  from: number | null
  to: number | null
}

export interface StepRegistrationState {
  form: {
    [StepRegistrationFieldList.name]: string
    [StepRegistrationFieldList.day]: string
    [StepRegistrationFieldList.month]: string
    [StepRegistrationFieldList.year]: string
    [StepRegistrationFieldList.email]: string
  }
  gender: Gender | null
  targetGender: Gender[] | Gender | null
  age: NumberRange | null
  variants: {
    target: FieldVariant<DatingGoals>[] | undefined
    constitution: FieldVariant<Constitution>[] | undefined
  }
  datingGoal: DatingGoals | null
  constitutions: Constitution[]
  personHeight: NumberRange | null
  personWeight: NumberRange | null
  searchTotalLoading: boolean
  searchTotal: number
  formErrors: Record<string, string>
}

const initialState: StepRegistrationState = {
  form: {
    name: '',
    day: '',
    month: '',
    year: '',
    email: '',
  },
  gender: null,
  targetGender: null,
  age: null,
  variants: {
    target: undefined,
    constitution: undefined,
  },
  datingGoal: null,
  constitutions: [],
  personHeight: null,
  personWeight: null,
  searchTotalLoading: false,
  searchTotal: 0,
  formErrors: {},
}

export const stepRegistrationReducer = (
  state = initialState,
  action:
    | RegistrationActionTypes
    | LocalStorageActionTypes
    | RestoreInitialStateAction
    | RegistrationDataAction
    | RegistrationAction
): StepRegistrationState => {
  switch (action.type) {
    case FETCH_REGISTRATION_SEARCH_SETTINGS:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        const {
          formOptions: {},
          formSettings: { fields },
        } = result

        const {
          target: { variants: target },
          constitution: { variants: constitution },
        } = fields as any // @TODO Некорректные типы из opeapi

        return {
          variants: {
            target,
            constitution,
          },
        }
      })

    case FETCH_REGISTRATION_SEARCH_RESULT:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          searchTotalLoading: true,
        }),
        (total) => ({
          searchTotal: total,
          searchTotalLoading: false,
        }),
        () => ({
          searchTotalLoading: false,
        })
      )

    case UPDATE_REGISTRATION_FIELD:
      return defaultUpdateFieldReducer(state, action)

    case UPDATE_REGISTRATION_GENDER:
      return {
        ...state,
        gender: action.value,
        targetGender:
          action.value === Gender.Male ? Gender.Female : Gender.Male,
      }

    case SELECT_REGISTRATION_TARGET_GENDER:
      if (action.value === Gender.Any) {
        return {
          ...state,
          targetGender: [Gender.Male, Gender.Female],
        }
      } else {
        return {
          ...state,
          targetGender: action.value,
        }
      }

    case SELECT_REGISTRATION_AGE:
      return {
        ...state,
        age: action.value,
      }

    case SET_DATING_GOAL:
      return {
        ...state,
        datingGoal: action.value,
      }

    case TOGGLE_CONSTITUTION:
      return {
        ...state,
        constitutions: toggleArrayValue(
          state.constitutions,
          action.value,
          action.enable
        ),
      }

    case SET_PERSON_HEIGHT:
      return {
        ...state,
        personHeight: action.value,
      }

    case SET_PERSON_WEIGHT:
      return {
        ...state,
        personWeight: action.value,
      }

    case RESTORE_INITIAL_STATE:
      if (action.stateName === 'stepRegistration') {
        return {
          ...state,
          ...action.initialState.stepRegistration,
        }
      }
      return state

    case REGISTER_ACTION:
    case REGISTER_WITH_EMAIL:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ formErrors: {} }),
        ({ formBuilder }) => {
          if (formBuilder) {
            return { formErrors: extractFormErrors(formBuilder) }
          }
          return {}
        }
      )

    case REGISTRATION_DATA:
      if (!action.result?.formBuilder) {
        return state
      }

      // Берем из формбилдера только поле email, остальное по ТЗ игнорируем https://redmine.mamba.ru/issues/116567
      const { email } = extractFieldsValues(action.result.formBuilder, {
        location: { value: '' },
      })

      if (!email.value || state.form.email) {
        return state
      }

      return {
        ...state,
        form: {
          ...state.form,
          email: email.value,
        },
      }

    case SAVE_STEP_REGISTRATION_DATA:
      return defaultPromiseReducer(state, action, undefined, () => ({
        ...initialState,
        // Нужно для отображения на последнем экране
        searchTotal: state.searchTotal,
      }))

    case CLEAR_STEP_REGISTRATION:
      return { ...initialState }

    case RESTORE_PARTIAL_STATE:
      if (action.name === nameReducer) {
        return {
          ...state,
          ...action.partialState.stepRegistration,
        }
      }

      return state

    default:
      return state
  }
}

const nameReducer = 'stepRegistration'
