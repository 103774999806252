import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { appAgreementIndexPath, appConfidentialityPath } from 'components/paths'
import { ModalLink } from 'components/presentational/link'

export const AgreementLink: FC = () => {
  return (
    <FormattedMessage
      id="boarding.legal_conditions"
      defaultMessage="Регистрируясь, вы соглашаетесь и принимаете условия {agreement} и {confidentiality}"
      values={{
        agreement: (
          <ModalLink to={appAgreementIndexPath} data-name="agreement">
            <FormattedMessage
              id="boarding.social_media_conditions_agreement"
              defaultMessage="Соглашения"
            />
          </ModalLink>
        ),
        confidentiality: (
          <ModalLink to={appConfidentialityPath} data-name="confidentiality">
            <FormattedMessage
              id="boarding.confidentiality_link"
              defaultMessage="Политики в отношении обработки персональных данных"
            />
          </ModalLink>
        ),
      }}
    />
  )
}
