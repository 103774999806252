import React from 'react'

import { useRouteMatch } from 'react-router'

import { AppRoute } from 'components/components.types'
import { landingLayoutContextId } from 'components/layout/MambaLayout/Context'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import {
  cityListPath,
  loginVariantsPath,
  shortcutListPath,
} from 'components/page/Boarding/boarding.paths'
import { unauthorizedCaptchaPath } from 'components/page/Captcha/Captcha.paths'
import { CaptchaPageLoadable } from 'components/page/Captcha/CaptchaPageLoadable'
import { OnboardingLoadable } from 'components/page/Onboarding/OnboardingLoadable'
import {
  changeLanguagePath,
  emailResetSuccessPath,
  enterBirthdayPath,
  enterNamePath,
  finalStepPath,
  loginPath,
  phoneResetSuccessPath,
  restorePasswordEmailSuccessPath,
  restorePasswordEnterPhoneCodePath,
  restorePasswordPath,
  restorePasswordPhoneSuccessPath,
  selectDatingPurposePath,
  selectGenderPath,
} from 'components/page/Onboarding/paths'
import { promoCodeRoutes } from 'components/page/PromoCode/promoCode.routes'
import {
  socialAuthorizationFailFullPath,
  socialAuthorizationSuccessPath,
} from 'components/page/SocialAuthorization/socialAuthorization.paths'
import { SocialAuthorizationFailLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationFailLoadable'
import { SocialAuthorizationSuccessLoadable } from 'components/page/SocialAuthorization/SocialAuthorizationSuccessLoadable'
import { EnterEmail } from 'components/page/StepRegistration/step/EnterEmail'
import {
  appAgreementIndexPath,
  appConfidentialityPath,
  confidentialityPath,
  enterEmailPath,
  indexPath,
  redirectAfterAuthPath,
} from 'components/paths'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { stepRegistrationReducer } from 'reducers/registration/stepRegistrationReducer'

import { OnboardingWithRedirect } from './OnboardingWithRedirect'

export const OnboardingSwitch = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })

  const match = useRouteMatch()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  if (!match || !partnerId) {
    return null
  }

  return (
    <CreateBaseUrl
      contextId={landingLayoutContextId}
      baseUrl={match.url}
      basePath={match.path}
    >
      <LocaleSwitch matchUrl={match.url} routes={ROUTES} />
    </CreateBaseUrl>
  )
}

/**
 * При навигации между роутами сладера в онбоардинге нужно использовать одинаковый ключ,
 * чтобы компоненты делали рендер, а не ремаунт. Иначе слайдер дергается.
 */
const SAME_ROUTE_KEY = 1

const ROUTES: AppRoute[] = [
  {
    path: socialAuthorizationFailFullPath,
    component: SocialAuthorizationFailLoadable,
  },
  {
    path: socialAuthorizationSuccessPath,
    component: SocialAuthorizationSuccessLoadable,
  },
  {
    path: unauthorizedCaptchaPath,
    component: CaptchaPageLoadable,
  },
  ...promoCodeRoutes,
  {
    path: enterEmailPath,
    component: EnterEmail,
  },
  ...[
    loginPath,
    loginVariantsPath,
    mergeAllUrls(finalStepPath, loginVariantsPath),
    restorePasswordPath,
    restorePasswordEmailSuccessPath,
    restorePasswordPhoneSuccessPath,
    restorePasswordEnterPhoneCodePath,
    phoneResetSuccessPath,
    emailResetSuccessPath,
    selectGenderPath,
    enterNamePath,
    enterBirthdayPath,
    selectDatingPurposePath,
    finalStepPath,
    changeLanguagePath,
    appAgreementIndexPath,
    confidentialityPath,
    appConfidentialityPath,
    changeLanguagePath,
    cityListPath,
    shortcutListPath,
    indexPath,
  ].map((route) => ({
    key: SAME_ROUTE_KEY,
    path: route,
    component: OnboardingLoadable,
  })),
  {
    key: SAME_ROUTE_KEY,
    path: mergeAllUrls(redirectAfterAuthPath, '*'),
    render: OnboardingWithRedirect,
  },
  {
    key: SAME_ROUTE_KEY,
    path: mergeAllUrls(selectGenderPath, redirectAfterAuthPath, '*'),
    render: OnboardingWithRedirect,
  },
]

export default OnboardingSwitch
